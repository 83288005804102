import Button from "components/Button/Button";
import {
  PricingPlan,
  PricingPlanBillingType,
  PricingPlanFeatureLabels,
} from "./PricingPlanOpts";
import BlueCheck from "src/icons/V2/Utility/CheckMark/BlueCheck20x20.svg";
import {
  PricingInterval,
  PricingPlans,
  getPricingPlanFromString,
} from "model/payments/payment.model";
import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { PlanAction } from "./PricingPlans";
import { useRouter } from "next/router";
import { formatNum } from "src/utils/format/formatNum";
import { PulseLoader } from "react-spinners";

const PricingPlanPricingLabels: Record<string, string> = {
  month: "month",
  monthly: "month",
  annual: "year",
};

interface PlanProps {
  plan: PricingPlan;
  bestValue?: boolean;
  billingCycle: PricingPlanBillingType;
  createPayment: (
    paymentPlan: PricingPlans | undefined,
    interval: PricingInterval,
  ) => Promise<void>;
  selectPlan: Dispatch<SetStateAction<PricingPlans | undefined>>;
  action?: PlanAction;
  isCurrentUserPlan?: boolean;
  isLoading?: boolean;
}

export default function Plan({
  plan,
  billingCycle,
  bestValue = false,
  createPayment,
  selectPlan,
  action = "create",
  isCurrentUserPlan,
  isLoading = false,
}: PlanProps) {
  const { features, name } = plan;
  const pricing = plan.prices;
  const type = getPricingPlanFromString(plan.name);
  const router = useRouter();

  const getButtonText = () => {
    switch (action) {
      case "create":
        return "Start building";
      case "upgrade":
        if (!isCurrentUserPlan) return "Upgrade";
        return "Current place";
      default:
        throw new Error("Unexpected value passed to getButton() handler");
    }
  };

  const handleSubmit = async () => {
    try {
      await createPayment(
        type,
        billingCycle === "annual" ? "Annual" : "Monthly",
      );
      selectPlan(type);
    } catch (e) {
      if ((e as any).response) {
        // Handle Axios errors
        if (action === "create") {
          // Redirect to dashboard if user is already on this plan
          // and they clicked "create"
          router.push("/dashboard");
        }
        // Otherwise, just propogate the error
      }
    }
  };

  return (
    <div className="group first:hidden lg:first:block lg:grow">
      {bestValue ? (
        <div className="py-1 pl-6 text-sm font-medium rounded-lg rounded-b-none bg-hmio-black-100 lg:text-center lg:pl-0">
          Best Value
        </div>
      ) : null}
      <div
        className={`bg-hmio-black-900 text-hmio-black-100 rounded-lg font-medium p-6 lg:px-0 group-last:lg:pr-6 box-border group-first:lg:pl-6 ${
          bestValue
            ? "border-hmio-black-100 border-2 border-solid rounded-t-none pt-4 border-t-0 lg:pt-6 lg:pb-7"
            : "lg:rounded-none lg:mt-7 group-last:lg:rounded-r-lg group-first:lg:rounded-l-lg"
        }`}
      >
        <div className="flex justify-between lg:flex-col lg:justify-center lg:text-center lg:group-first:hidden">
          <h2>{name}</h2>
          <div>
            <span className="mr-1 text-xl font-bold">
              ${formatNum((pricing[billingCycle]?.price ?? 0) / 100, 2)}
            </span>
            <span className="text-hmio-black-400">
              /{PricingPlanPricingLabels[billingCycle]}
            </span>
          </div>
        </div>
        <div className="lg:group-first:pt-[66px] text-sm">
          <ol>
            {[...PricingPlanFeatureLabels.keys()].map((key) => (
              <li
                key={key}
                // Group-first:lg is the legend on large screens
                className={`${
                  !features[key] ? "hidden lg:flex" : "flex"
                } items-center justify-between
                lg:justify-center
                group-first:lg:justify-start
                border-0
                border-b border-solid
                border-hmio-black-700
                py-3 first:lg:border-t first:lg:mt-[18px]`}
              >
                {/* When screen sizes become large, hide the label (there is a legend column) */}
                <div className="lg:hidden group-first:block">
                  {PricingPlanFeatureLabels.get(key)}
                </div>
                <div className="group-first:invisible">
                  {features[key] === "true" ? (
                    <BlueCheck className="w-5 h-5" />
                  ) : (
                    <div
                      className={`${
                        key === "overage" ? "min-h-10" : "min-h-[1.25rem]"
                      } min-w-[1.25rem] text-right lg:text-center lg:max-w-[180px]`}
                    >
                      {features[key]}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </div>
        <div className="flex mt-6 lg:justify-center group-first:lg:h-10">
          <PlanButton
            isLoading={isLoading}
            type={type}
            createPayment={createPayment}
            selectPlan={selectPlan}
            billingCycle={billingCycle}
            isCurrentUserPlan={isCurrentUserPlan}
            onClick={() => handleSubmit()}
          >
            {getButtonText()}
          </PlanButton>
        </div>
      </div>
    </div>
  );
}

function PlanButton({
  onClick,
  children,
  isCurrentUserPlan,
  isLoading = false,
}: Pick<PlanProps, "createPayment" | "billingCycle" | "selectPlan" | "isLoading"> & {
  type: PricingPlans | undefined;
  isCurrentUserPlan?: boolean;
  onClick?: () => void;
} & PropsWithChildren) {
  return (
    <Button
      style="primary"
      className={`px-8 group-first:hidden ${
        isCurrentUserPlan ? "bg-hmio-black-400" : ""
      }`}
      disabledClassName="opacity-1"
      onClick={onClick}
      disabled={isCurrentUserPlan}
    >
      {isLoading ? (<PulseLoader color="#E4E5E7" size={7} />) : children }
    </Button>
  );
}
