import Button from "components/Button/Button";
import CloseMedium from "src/icons/V2/Utility/Close/CloseMedium.svg";
import type { PaymentType } from "model/payments/payment.model";

interface PaymentSelectionModalProps {
  onCancel: () => void;
  onContinue: (paymentType: PaymentType) => void;
}

export function PaymentSelectionModal({
  onContinue,
  onCancel,
}: PaymentSelectionModalProps) {
  return (
    <div className="flex flex-col items-center px-10 rounded-lg bg-hmio-black-900 preference-modal text-hmio-black-100 py-10 mt-0">
      <h3 className="flex justify-between w-full items-center">
        Select a payment method
        <button onClick={onCancel}>
          <CloseMedium />
          <span className="sr-only">Close modal</span>
        </button>
      </h3>
      <Button
        sizing="large"
        style="primary"
        outline={2}
        className="text-sm font-medium text-hmio-black-100 mt-8 w-full"
        onClick={() => onContinue("Card")}
      >
        Pay with card
      </Button>
      <Button
        sizing="large"
        style="primary"
        outline={2}
        className="text-sm font-medium text-hmio-black-100 mt-4 w-full"
        onClick={() => onContinue("Crypto")}
      >
        Pay with crypto via CandyPay
      </Button>
    </div>
  );
}
