export type ApiCustomer = {
  subscriptionId: string;
  customerId: string;
};

export enum PricingPlans {
  Free = "Free",
  Developer = "Developer",
  Business = "Business",
}

export type PaymentType = "Card" | "Crypto";

export function getPricingPlanFromString(s: string) {
  switch (s) {
    case "Free":
      return PricingPlans.Free;
    case "Developer":
    case "Developers":
      return PricingPlans.Developer;
    case "Business":
    case "Businesses":
      return PricingPlans.Business;
    default:
      throw new Error(`Illegal argument for PricingPlans: ${s}`);
  }
}

export type PricingInterval = "Monthly" | "Annual";
export type PricingPlanTiers = "Free" | "Developer" | "Business" | "Enterprise";
