export const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(23, 24, 26, 0.8)", // hmio-black-DEFAULT
  },
  content: {
    backgroundColor: "rgba(33, 35, 37, 1)",
    borderRadius: "16px",
    width: "auto",
    border: 0,
    top: "200px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translateX(-50%)",
    padding: 0,
    zIndex: 5000,
  },
};
