import { AuthContext } from "components/AuthProvider/AuthProvider";
import {
  PaymentType,
  PricingInterval,
  PricingPlans,
} from "model/payments/payment.model";
import { useContext, useEffect, useState } from "react";
import { useCreatePaymentSession } from "src/hooks/api/usePayment";
import Plan from "./Plan";
import Anchor from "components/Anchor/Anchor";
import Button from "components/Button/Button";
import usePricingPlans from "src/hooks/stripe/usePricingPlans";
import Loader from "components/Loader/Loader";
import { useApiKeysForUser } from "src/hooks/api/useApiKeys";
import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import ReactModal from "react-modal";
import { customModalStyles } from "components/Modal/customModalStyles";
import { PaymentSelectionModal } from "components/Payments/PaymentSelectionModal/PaymentSelectionModal";
import { getOldestApiKey } from "src/utils/auth/getOldestApiKey";

// Create -- create new
// Upgrade -- upgrade existing
export type PlanAction = "upgrade" | "create";

interface ConfirmationState {
  showModal: boolean;
  confirmed: boolean;
  pricingPlan?: PricingPlans | null;
  pricingInterval?: PricingInterval | null;
}

interface PaymentMethodState {
  showModal: boolean;
  confirmed: boolean;
  paymentType: PaymentType;
}

/** Default action = "create", e.g. buttons will create new API keys */
export default function Plans({
  action = "create",
  currentUserPlan,
  onSuccess,
  onError,
  setIsModalOpen,
}: {
  action?: PlanAction;
  currentUserPlan?: PricingPlans;
  setIsModalOpen?: (isModalOpen: boolean) => void;
  onSuccess?: (...args: any) => void;
  onError?: (...args: any) => void;
}) {
  const [cycle, setCycle] = useState<"monthly" | "annual">("monthly");
  const { data: plans, error } = usePricingPlans();
  const [selectedPlan, setSelectedPlan] = useState<PricingPlans | undefined>(
    undefined,
  );

  const [paymentMethodState, setPaymentMethodState] =
    useState<PaymentMethodState>({
      showModal: false,
      confirmed: false,
      paymentType: "Card",
    });

  const [confirmationState, setConfirmationState] = useState<ConfirmationState>(
    {
      showModal: false,
      confirmed: false,
      pricingPlan: null,
      pricingInterval: null,
    },
  );

  const { data: apiKeys } = useApiKeysForUser();

  const { createPayment, isLoading } = useCreatePaymentSession({
    ignoreRedirect: action === "upgrade" && currentUserPlan !== "Free" && paymentMethodState.paymentType === "Card",
  });

  const handleCreatePayment = async (
    plan: PricingPlans | undefined,
    interval: PricingInterval,
    paymentType: PaymentType,
  ) => {
    try {
      if (apiKeys?.length) {
        const tier = getOldestApiKey(apiKeys)?.tier;
        if (!tier || tier === "Free") {
          if (!paymentMethodState.confirmed) {
            return setPaymentMethodState({
              showModal: true,
              confirmed: false,
              paymentType,
            });
          }
        } else if (!confirmationState.confirmed) {
          // No need to show modal if they clicked the same plan
          if (tier === plan) return;
          return setConfirmationState({
            showModal: true,
            confirmed: false,
            pricingPlan: plan,
            pricingInterval: interval,
          });
        }
      }

      await createPayment(plan, interval, paymentType);
      onSuccess?.();
      setPaymentMethodState({
        showModal: false,
        confirmed: false,
        paymentType: "Card",
      });
      return setConfirmationState({
        showModal: false,
        confirmed: false,
        pricingPlan: null,
      });
    } catch (error) {
      onError?.();
    }
  };

  useEffect(() => {
    if (!confirmationState.confirmed) return;
    const run = async () => {
      await handleCreatePayment(
        confirmationState.pricingPlan!,
        confirmationState.pricingInterval!,
        paymentMethodState.paymentType,
      );
    };
    run()
      .then(() => {})
      .catch(() => {});
  }, [
    confirmationState.confirmed,
    confirmationState.pricingPlan,
    confirmationState.pricingInterval,
    paymentMethodState.paymentType,
  ]);

  useEffect(() => {
    if (!paymentMethodState.confirmed) return;
    const run = async () => {
      await handleCreatePayment(
        selectedPlan!,
        "Monthly",
        paymentMethodState.paymentType,
      );
    };
    run()
      .then(() => {})
      .catch(() => {});
  }, [
    paymentMethodState.confirmed,
    selectedPlan,
    confirmationState.pricingInterval,
    paymentMethodState.paymentType,
  ]);

  const { isLoggedIn } = useContext(AuthContext);

  /**
   * User Flow:
   * 1. If User clicks on plan without being logged in, they are redirected to login, and sign the transaction
   * 2. Once the transaction is signed successfully, the user isLoggedIn and the useEffect below is triggered
   * 3. Which invokes the createPayment function, with the originally selected payment plan.
   */
  useEffect(() => {
    if (isLoggedIn && selectedPlan) {
      createPayment(
        selectedPlan,
        cycle === "annual" ? "Annual" : "Monthly",
        paymentMethodState.paymentType,
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setIsModalOpen?.(paymentMethodState.showModal || confirmationState.showModal);
  }, [paymentMethodState.showModal || confirmationState.showModal]);

  return (
    <>
      <ReactModal
        isOpen={paymentMethodState.showModal}
        onRequestClose={() =>
          setPaymentMethodState({
            showModal: false,
            confirmed: false,
            paymentType: "Card",
          })
        }
        style={{...customModalStyles, content: { ...customModalStyles.content, zIndex: 9991, } }}
      >
        <PaymentSelectionModal
          onContinue={(type) => {
            setPaymentMethodState({
              showModal: false,
              confirmed: true,
              paymentType: type,
            });
          }}
          onCancel={() =>
            setPaymentMethodState({
              showModal: false,
              confirmed: false,
              paymentType: "Card",
            })
          }
        />
      </ReactModal>
      <ReactModal
        isOpen={confirmationState?.showModal}
        onRequestClose={() =>
          setConfirmationState({
            showModal: false,
            confirmed: false,
            pricingPlan: null,
            pricingInterval: null,
          })
        }
        style={customModalStyles}
      >
        <ConfirmationModal
          onCancel={() =>
            setConfirmationState({
              showModal: false,
              confirmed: false,
              pricingPlan: null,
              pricingInterval: null,
            })
          }
          onConfirm={() => {
            setConfirmationState((prev) => ({
              showModal: false,
              confirmed: true,
              pricingPlan: prev.pricingPlan,
              pricingInterval: prev.pricingInterval,
            }));
          }}
          onClose={() =>
            setConfirmationState({
              showModal: false,
              confirmed: false,
              pricingPlan: null,
              pricingInterval: null,
            })
          }
          header={<h3 className="h3">Change Subscription?</h3>}
          body={
            <div>
              Are you should you&apos;d like to change your subscription?
            </div>
          }
          confirmText="Change Plan"
        />
      </ReactModal>
      {/* We're not currently doing annual pricing, but we will at some point. This is just a fancy button to do that */}
      {/*
      <div className="flex flex-col justify-between gap-8 mb-14 lg:items-center lg:flex-row lg:gap-0">
        <div className="bg-hmio-black-900 flex items-center py-1.5 px-4 gap-8 rounded-full relative grow-0 w-fit">
          <button
            onClick={() => setCycle("month")}
            className={`
              ${cycle === "month" ? "text-hmio-black" : "text-hmio-black-300"}
              z-10
              text-sm
              font-medium
              `}
          >
            Monthly
          </button>
          <button
            onClick={() => setCycle("annual")}
            className={`
              ${cycle === "annual" ? "text-hmio-black" : "text-hmio-black-300"}
              z-10
              text-sm
              font-medium
              `}
          >
            Annually
          </button>
          <div
            className={`w-1/2 absolute h-full bg-hmio-black-100 rounded-full ${
              cycle === "month" ? "-translate-x-4" : "translate-x-[80%]"
            } duration-200`}
          />
        </div>
      </div>
        */}
      <div className="flex flex-col justify-center w-full gap-10 lg:flex-row lg:gap-0">
        {plans ? (
          <>
            <Plan
              isLoading={isLoading}
              plan={plans["Businesses"]}
              billingCycle={cycle}
              createPayment={(paymentPlan, interval) =>
                handleCreatePayment(
                  paymentPlan,
                  interval,
                  paymentMethodState.paymentType,
                )
              }
              selectPlan={setSelectedPlan}
            />
            <Plan
              isLoading={isLoading}
              plan={plans["Free"]}
              billingCycle={cycle}
              createPayment={(paymentPlan, interval) =>
                handleCreatePayment(
                  paymentPlan,
                  interval,
                  paymentMethodState.paymentType,
                )
              }
              selectPlan={setSelectedPlan}
              action={action}
              isCurrentUserPlan={currentUserPlan === PricingPlans.Free}
            />
            <Plan
              isLoading={isLoading}
              plan={plans["Developers"]}
              billingCycle={cycle}
              bestValue
              createPayment={(paymentPlan, interval) =>
                handleCreatePayment(
                  paymentPlan,
                  interval,
                  paymentMethodState.paymentType,
                )
              }
              selectPlan={setSelectedPlan}
              action={action}
              isCurrentUserPlan={currentUserPlan === PricingPlans.Developer}
            />
            <Plan
              isLoading={isLoading}
              plan={plans["Businesses"]}
              billingCycle={cycle}
              createPayment={(paymentPlan, interval) =>
                handleCreatePayment(
                  paymentPlan,
                  interval,
                  paymentMethodState.paymentType,
                )
              }
              selectPlan={setSelectedPlan}
              action={action}
              isCurrentUserPlan={currentUserPlan === PricingPlans.Business}
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
      <div className="hidden w-full mx-6 mt-4 text-xs font-medium whitespace-pre md:block text-hmio-black-400">
        1 Webhook Push = 1 credit{"   "}•{"   "}1 API Call = 100 credits{"   "}•{"   "}1 RPC call = 1 credits
      </div>
      <div className="p-6 mt-10 rounded-lg bg-hmio-black-900">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-semibold text-hmio-black-100">
            Enterprise
          </h3>
          <Anchor to="mailto:inquiries@hellomoon.io">
            <Button
              style="primary"
              outline={2}
              className="text-hmio-black-100 py-2.5 px-10 text-sm font-medium"
            >
              Contact Us
            </Button>
          </Anchor>
        </div>
        <div className="text-hmio-black-400 text-sm font-medium mt-5 max-w-[759px]">
          <p>
            Looking for a more custom tailored solution? Reach out to us to see
            what our partners are using – lower latency, custom data streams,
            historical data to genesis block, volume-based pricing, and
            enterprise-level support.
          </p>
        </div>
      </div>
    </>
  );
}
