import enhancedAxios from "clients/requestUtil";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import type { ApiKeyModel } from "model/apiKey/ApiKeyModel";
import { useContext } from "react";
import useSWR from "swr";

const _baseUri = "/api/apiKeys";
export const SwrApiKeys = "apiKeys";

const getAuthTokenHeader = (token: string) => ({
  headers: { Authorization: `Bearer ${token}` },
});

export const useApiKeysForUser = () => {
  const { token } = useContext(AuthContext);
  const { data, mutate, error } = useSWR(
    token ? [SwrApiKeys] : null,
    async () =>
      (
        await enhancedAxios.get<ApiKeyModel[]>(
          _baseUri,
          getAuthTokenHeader(token!),
        )
      ).data,
  );
  return { data, mutate, error };
};
