import Close from "src/icons/V2/Utility/Close.svg";

export interface ConfirmationModalProps {
  header: JSX.Element;
  body: JSX.Element;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
}

export const ConfirmationModal = ({
  header,
  body,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  onClose,
}: ConfirmationModalProps) => {
  return (
    <div className="flex flex-col text-white px-10 my-10 preference-modal">
      <div className="w-full flex justify-between">
        <div>{header}</div>
        {onClose && (
          <button
            onClick={onClose}
            className="text-hmio-black-400 hover:text-hmio-black-300"
          >
            <Close />
            <span className="sr-only">Close</span>
          </button>
        )}
      </div>
      <section className="mt-6 mb-10 max-h-80 overflow-y-auto">{body}</section>
      <section className="flex justify-between w-full">
        <button
          className="text-hmio-black-400 text-sm hover:text-hmio-black-300"
          onClick={onCancel}
        >
          {cancelText || "Cancel"}
        </button>
        <button
          className="text-hmio-black-400 text-sm hover:text-hmio-black-300"
          onClick={onConfirm}
        >
          {confirmText || "Confirm"}
        </button>
      </section>
    </div>
  );
};
