import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import enhancedAxios from "clients/requestUtil";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import {
  PaymentType,
  PricingInterval,
  PricingPlans,
} from "model/payments/payment.model";
import { useContext, useMemo, useState } from "react";
import { getAuthTokenHeader } from "src/utils/auth/getAuthTokenHeader";
import { useSWRConfig } from "swr";
import { SwrApiKeys } from "./useApiKeys";

const _baseUri = "/api/payments";

export const useCreatePaymentSession = (args?: {
  ignoreRedirect?: boolean;
}) => {
  const { token, isWalletConnected, signIn } = useContext(AuthContext);
  const { setVisible: setWalletModalVisible } = useWalletModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createPayment = useMemo(
    () =>
      async (
        pricingPlan: PricingPlans | undefined,
        interval: PricingInterval,
        paymentType: PaymentType,
      ) => {
        if (!token && !isWalletConnected) return setWalletModalVisible(true);

        if (!token) return signIn();
        if (!pricingPlan) return;
        setIsLoading(true);
        try {
          const result = await enhancedAxios.get(
            `${_baseUri}/create-session?` +
              new URLSearchParams({
                pricingPlan,
                interval,
                paymentType,
              }),
            getAuthTokenHeader(token),
          );
          setIsLoading(false);
          if (result.status === 200 && !args?.ignoreRedirect)
            window.open(result.data.location, "_blank");
          return result.data;
        } catch (e) {
          setIsLoading(false);
          return null;
        }
      },
    [token, args?.ignoreRedirect],
  );

  return { createPayment, isReady: !!token, isLoading };
};

export const useCancelPayment = () => {
  const { token, isWalletConnected, signIn } = useContext(AuthContext);
  const { setVisible: setWalletModalVisible } = useWalletModal();
  const { mutate } = useSWRConfig();

  const cancelPayment = useMemo(
    () => async () => {
      if (!token && !isWalletConnected) return setWalletModalVisible(true);

      if (!token) return signIn();
      await enhancedAxios.delete(
        `${_baseUri}/cancel`,
        getAuthTokenHeader(token),
      );
      await mutate(SwrApiKeys);
    },
    [token],
  );

  return { cancelPayment, isReady: !!token };
};
