import type { ApiKeyModel } from "model/apiKey/ApiKeyModel";

export const getOldestApiKey = (apiKeys: ApiKeyModel[] | null | undefined) => {
  if (!apiKeys?.length) return null;
  const sortedKeys = apiKeys.sort((a, b) => {
    return new Date(a.createdDate ?? new Date()).getTime() >
      new Date(b.createdDate ?? new Date()).getTime()
      ? 1
      : -1;
  });
  return sortedKeys[0];
};
